@import "./src/styles/global/vars";

$scoreCoef: 0.2vh;
$scoreWidth: 200;
$scoreHeight: 150;

.ScorePanel {
  overflow: hidden;
  display: flex;
  flex-direction: row;

  width: $scoreCoef * $scoreWidth;
  height: $scoreCoef * $scoreHeight;

  border: 1px solid $blueColor;
  border-radius: 10px;

  line-height: 1.2;
  text-align: center;

  &Column {
    flex: 0.5;

    padding-top: $scoreCoef * 15;

    background-color: $whiteColor;

    color: $blueColor;

    &:last-child {
      background-color: $blueColor;

      color: $whiteColor;
    }
  }
  &Label {
    font-size: $scoreCoef * 10;
  }
  &Value {
    font-size: $scoreCoef * 100;
  }
}
