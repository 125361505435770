.button {
  display: block;

  width: 200px;

  margin: 22px auto 0;
  padding: 10px 0;

  background-color: $blueColor;
  border-radius: 200px;

  font-size: 14px;
  font-weight: 500;
  color: $whiteColor;
  line-height: 1.15;
  text-transform: uppercase;

  cursor: pointer;
}
