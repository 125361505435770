.Footer {
  position: fixed;
  bottom: 10px;
  right: 10px;

  font-size: 0.8vw;

  &Label {
  }
  &Link {
    color: #6ea6fb;

    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  &Logo {
    height: 1.6vw;

    margin-bottom: -0.5vw;
  }
}
