@import "./src/styles/global/vars";

.Wheel {
  position: fixed;
  top: 50%;
  left: 60%;

  width: 80vh;
  height: 80vh;

  transform: translate(-100%, -50%);

  background-color: #302d2d;
  border: 5px solid $whiteColor;
  border-radius: 50%;
}

.Lines {
  list-style: none;

  position: fixed;
  top: 50%;
  left: 50%;

  &Item {
    position: absolute;
    top: 0;
    left: 0;

    width: 0;
    height: 0;

    @for $i from 1 through 13 {
      &:nth-child(#{$i}) {
        transform: rotate(#{$i / 13 * 360}deg);
      }
    }

    &:before {
      content: '';

      position: absolute;
      left: 0;
      top: 0;

      width: 40vh;
      height: 2px;

      margin-top: -1px;

      background-color: $whiteColor;
    }
  }
}

.MoveArrows {
  $moveArrowsCoef: 0.16vh;
  $moveArrowsLineWidth: 12;
  $moveArrowsLineHeight: 35;
  $moveArrowsTriangleWidth: 30;
  $moveArrowsTriangleHeight: 26;
  $moveArrowsHalfMainHeight: ($moveArrowsLineHeight + $moveArrowsTriangleHeight) / 2;

  list-style: none;

  position: fixed;
  top: 50%;
  left: 50%;

  &Item {
    position: absolute;
    top: 0;
    left: 0;

    width: 0;
    height: 0;

    @for $i from 1 through 13 {
      &:nth-child(#{$i}) {
        transform: rotate(#{$i / 13 * 360 + (360 / 13 * 0.5)}deg);
      }
    }

    &:before {
      content: '';

      position: absolute;
      top: 0;
      left: 35vh;

      width: $moveArrowsCoef * $moveArrowsLineWidth;
      height: $moveArrowsCoef * $moveArrowsLineHeight;

      margin-left: $moveArrowsCoef * -($moveArrowsLineWidth / 2);
      margin-top: $moveArrowsCoef * -$moveArrowsHalfMainHeight;

      background-color: $whiteColor;
    }
    &:after {
      content: '';

      position: absolute;
      top: 0;
      left: 35vh;

      width: 0;
      height: 0;

      margin-left: $moveArrowsCoef * -($moveArrowsTriangleWidth / 2);
      margin-top: $moveArrowsCoef * ($moveArrowsLineHeight - $moveArrowsHalfMainHeight);

      border-left: #{$moveArrowsCoef * $moveArrowsTriangleWidth / 2} solid transparent;
      border-top: #{$moveArrowsCoef * $moveArrowsTriangleHeight} solid $whiteColor;
      border-right: #{$moveArrowsCoef * $moveArrowsTriangleWidth / 2} solid transparent;
    }
  }
}

$questionCoef: 0.14vh;
$questionWidth: 100;
$questionHeight: 50;

.Questions {
  list-style: none;

  position: fixed;
  top: 50%;
  left: 50%;

  transform: rotate(-90deg);

  &Item {
    position: absolute;
    top: 0;
    left: 0;

    cursor: pointer;

    @for $i from 1 through 13 {
      &:nth-child(#{$i}) {
        transform: rotate(#{($i - 1) / 13 * 360}deg);
      }
    }

    &Opened {
      display: none;
    }

    &:before {
      content: '';

      position: absolute;
      top: -39vh;
      left: 0;

      width: $questionCoef * $questionWidth;
      height: $questionCoef * $questionHeight;

      margin-left: -($questionCoef * $questionWidth / 2);

      background-color: $whiteColor;
    }
  }
  &TextWrapper {
    position: absolute;
    top: -39vh;
    left: 0;
    right: 0;

    width: $questionCoef * $questionWidth;
    height: 4vh;

    margin-left: -($questionCoef * $questionWidth / 2);
    margin-top: $questionCoef * $questionHeight / 2;

    transform: translateY(-50%);

    background-color: $greyColor;
    border-top: #{$questionCoef * 1} solid $redColor;
    border-bottom: #{$questionCoef * 1} solid $redColor;

    font-size: 2vh;
    color: $blackColor;
    line-height: 1.5;
    text-align: center;
  }
  &ItemBlitz &TextWrapper {
    border-top-color: $yellowColor;
    border-bottom-color: $yellowColor;
    background-color: $redColor;

    color: $yellowColor;
  }
  &ItemSuperBlitz &TextWrapper {
    border-top-color: $yellowColor;
    border-bottom-color: $yellowColor;
    background-color: $greenColor;

    color: $yellowColor;
  }
  &Text {
    position: absolute;
    top: 50%;
    left: 0;

    min-width: 100%;

    transform: translateY(-50%);

    font-size: 100%;
    text-align: center;
  }
}

.Arrow {
  $arrowCoef: 0.1vh;
  $arrowLineWidth: 300;
  $arrowLineHeight: 20;
  $arrowTriangleWidth: 16;

  position: absolute;
  top: 50%;
  left: 50%;

  width: 0;
  height: 0;

  &:before {
    content: '';

    position: absolute;
    top: 0;
    right: 0;

    width: $arrowCoef * $arrowLineWidth;
    height: $arrowCoef * $arrowLineHeight;

    margin-right: 1px;
    margin-top: -($arrowCoef * $arrowLineHeight / 2);

    background-color: $redColor;
    border-radius: 2px 0 0 2px;
  }
  &:after {
    content: '';

    position: absolute;
    top: 0;
    right: $arrowCoef * $arrowLineWidth;

    width: 0;
    height: 0;

    margin-top: -($arrowCoef * $arrowLineHeight / 2);

    border-top: #{$arrowCoef * $arrowLineHeight / 2} solid transparent;
    border-right: #{$arrowCoef * $arrowTriangleWidth / 2} solid $redColor;
    border-bottom: #{$arrowCoef * $arrowLineHeight / 2} solid transparent;
  }
}

.Roulette {
  $rouletteCoef: 0.2vh;
  $rouletteWidth: 100;
  $rouletteHeight: 100;
  $rouletteCoreSize: 20;
  $rouletteCoreBorderSize: 2;
  $rouletteLineHeight: 3;
  $rouletteLineBorderSize: 1;

  list-style: none;

  position: fixed;
  top: 50%;
  left: 50%;

  width: $rouletteCoef * $rouletteWidth;
  height: $rouletteCoef * $rouletteHeight;

  margin-left: -($rouletteCoef * $rouletteWidth / 2);
  margin-top: -($rouletteCoef * $rouletteHeight / 2);

  background-color: $greenColor;
  border: 6px solid $yellowColor;
  border-radius: 50%;

  cursor: pointer;

  &:after {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;

    width: $rouletteCoef * $rouletteCoreSize;
    height: $rouletteCoef * $rouletteCoreSize;

    margin-left: -(($rouletteCoef * $rouletteCoreSize / 2) + ($rouletteCoef * $rouletteCoreBorderSize));
    margin-top: -(($rouletteCoef * $rouletteCoreSize / 2) + ($rouletteCoef * $rouletteCoreBorderSize));

    background-color: $yellowColor;
    border: #{$rouletteCoef * $rouletteCoreBorderSize} solid $redGreyColor;
    border-radius: 50%;
  }
  &Line {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 0;
    height: 0;

    @for $i from 1 through 8 {
      &:nth-child(#{$i}) {
        transform: rotate(#{($i / 8 * 360) + (360 / 8 * 0.8)}deg);
      }
    }

    &:before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;

      width: $rouletteCoef * $rouletteWidth / 2;
      height: $rouletteCoef * $rouletteLineHeight;

      margin-top: -(($rouletteCoef * $rouletteLineHeight / 2) + ($rouletteCoef * $rouletteLineBorderSize));

      background-color: $yellowColor;
      border-top: ($rouletteCoef * $rouletteLineBorderSize) solid $redColor;
      border-bottom: ($rouletteCoef * $rouletteLineBorderSize) solid $redColor;
    }
  }
}

.CurrentQuestion {
  position: absolute;

  background-color: #f00;

  cursor: pointer;

  &Wrapper {
    position: absolute;
    top: 0;
    left: 0;

    width: $questionCoef * $questionWidth;
    height: $questionCoef * $questionHeight;

    margin-left: -($questionCoef * $questionWidth / 2);
    margin-top: -($questionCoef * $questionHeight / 2);

    background-color: $whiteColor;

    text-align: center;
  }
  &TextWrapper {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;

    height: 4vh;

    transform: translateY(-50%);

    background-color: $greyColor;
    border-top: #{$questionCoef * 1} solid $redColor;
    border-bottom: #{$questionCoef * 1} solid $redColor;

    font-size: 2vh;
    line-height: 1.5;
    color: $blackColor;
    text-align: center;
  }
  &Blitz &TextWrapper {
    border-top-color: $yellowColor;
    border-bottom-color: $yellowColor;
    background-color: $redColor;

    color: $yellowColor;
  }
  &SuperBlitz &TextWrapper {
    border-top-color: $yellowColor;
    border-bottom-color: $yellowColor;
    background-color: $greenColor;

    color: $yellowColor;
  }
  &Text {
    position: absolute;
    top: 50%;
    left: 0;

    min-width: 100%;

    transform: translateY(-50%);

    font-size: 100%;
    text-align: center;
  }
}

.Score {
  position: fixed;
  top: 50%;
  right: 35%;

  transform: translate(100%, -50%);

  cursor: pointer;
}

.SettingsBtn {
  position: fixed;
  top: 10px;
  right: 10px;

  cursor: pointer;
}
