.Container {
  display: flex;
  flex-direction: row;

  padding: 30px 20px;
}

.Form {
  flex: 0.7;
}

.Row {
  display: flex;
  flex-direction: row;
}

.Column {
  position: relative;

  flex: 0 0 auto;

  margin: 10px 10px;

  text-align: center;

  &:nth-child(1) {
    flex: 0;

    min-width: 20px;

    font-size: 16px;
    line-height: 30px;
  }
  &:nth-child(2) {
    flex: 0;

    min-width: 150px;
  }
  &:nth-child(3) {
    flex: 1;
  }
  &:nth-child(4) {
    flex: 0;

    min-width: 70px;
  }
}

.Row:first-child .Column {
  padding-top: 30px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    text-align: center;
  }

  &:nth-child(2):before {
    content: 'Тип'
  }
  &:nth-child(3):before {
    content: 'Город'
  }
  &:nth-child(4):before {
    content: 'Сыграно'
  }
}

.Field,
.Select {
  box-sizing: border-box;

  width: 100%;

  padding: 3px 5px;

  font-size: 20px;

  &:disabled {
    opacity: 0.5;
  }
}

.Checkbox {
}

.SetupPanel {
  flex: 0.3;

  padding: 10px 20px;

  text-align: center;

  &Title {
    margin-bottom: 10px;

    font-size: 16px;
  }
}

.StartScore {
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin: 0 auto;

  line-height: 2;

  &Column {
    margin: 0 20px;
  }
  .Field {
    width: 50px;
  }
}
