$icon_size: 1em;

.gear {
  display: inline-block;
  position: relative;
  margin: $icon_size/5;
  width: $icon_size;
  height: $icon_size;
  background: #dedede;
  border-radius: 50%;
  border: $icon_size/3.5 solid gray;
  box-sizing: border-box;

  &:before,
  &:after {
    content: '×';
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: 'Times New Roman';
    font-weight: bold;
    font-size: $icon_size*2.5;
    color: gray;
  }

  &:after {
    transform: translate(-50%,-50%) rotate(45deg);
  }
}
