* {
  min-width: 0;
}

ul, ol, p, h1, h2, h3, h4, h5, dl, dt, dd {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
select,
textarea,
button {
  padding: 0;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
