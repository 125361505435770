@import "./src/styles/global/vars";

.Container {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.Timer {
  margin-bottom: 50px;
  padding: 20px 45px;

  background-color: $whiteColor;
  border: 1px solid $greyColor;
  box-sizing: border-box;
  border-radius: 10px;

  font-size: 100px;
  color: $greyColor;
  line-height: 1.2;

  &Started {
    border-color: $blueColor;

    color: $blueColor;
  }
}
